import * as React from "react"
import {graphql, withPrefix} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../../components/PageHeaderBlock";
import {Col, Container, Row, Toast, ToastContainer} from "react-bootstrap";
import ArticleWidget from "../../components/widgets/ArticleWidget";
import ArticleWidget2 from "../../components/widgets/ArticleWidget2";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useEffect, useRef, useState} from "react";
import copy from 'clipboard-copy';
import {ADJECTIVES, NOUNS} from "../../components/NicknameGeneratorConstants";
import SeoBlock from "../../components/SeoBlock";

export default function NicknameGenerator() {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const [generatedNickname, setGeneratedNickname] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [showBadToast, setShowBadToast] = useState(false);

    function generateNickname() {
        const randomAdj = ADJECTIVES[Math.floor(Math.random() * ADJECTIVES.length)];
        const randomNoun = NOUNS[Math.floor(Math.random() * NOUNS.length)];
        setGeneratedNickname(`${randomAdj}${randomNoun}`);
    }

    const handleInputClick = () => {
        // Выбираем текст внутри инпута при клике
        inputRef.current.select();
    };

    const handleCopyToClipboard = () => {
        if (inputRef.current) {
            const inputValue = inputRef.current.value;
            copy(inputValue)
                .then(() => {
                    setShowBadToast(false);
                    setShowToast(true);
                })
                .catch(() => {
                    setShowBadToast(true);
                    setShowToast(true);
                });
        }
    };

    useEffect(() => {
        generateNickname();
    }, []);

    return (
        <Layout>
            <Seo title={t('nicknameGenerator')} description={t('nicknameGeneratorH1')}
                 image="../../services/nickname-generator.png"/>
            <PageHeaderBlock h1="nicknameGeneratorH1"/>
            <Container fluid className="px-flex">
                <Breadcrumbs firstChild="ourServicesWord" firstChildUrl={'/services'} secondChild="nicknameGenerator"/>
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget/></Col>
                    <Col md={8} xxl={7}>
                        <Row>
                            <Col sm={12} className="text-center mt-3 mt-md-0 mt-xl-4">
                                <strong
                                    className="d-block d-md-inline text-center mt-3">{t('generatedNickname')}:</strong>
                                <div className="mt-3 mt-xl-4 mt-xxl-5 text-center">
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        value={generatedNickname}
                                        className="text-center ms-2 ms-xl-3 me-3"
                                        onClick={handleInputClick}
                                    />
                                    <button className="btn btn__big outline small" onClick={handleCopyToClipboard}>
                                        <span className="d-none d-md-inline">{t('copyWord')}</span>
                                        <svg className="svg-icon d-md-none">
                                            <use xlinkHref={withPrefix(`sprite.svg#copy-icon`)}></use>
                                        </svg>
                                    </button>
                                </div>
                            </Col>
                            <Col sm={12} className="text-center py-5 py-md-0 py-xl-5 my-4 my-md-1 my-xxl-5">
                                <button className="btn__big"
                                        onClick={generateNickname}>{t('generateNickname')}</button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2/></Col>
                </Row>
            </Container>
            <SeoBlock text="nicknameSeoText" variable={{count: ADJECTIVES.length * NOUNS.length}} />
            <ToastContainer
                className="toast-style p-3 p-xxl-4 position-fixed"
                style={{ zIndex: 1000 }}
            >
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
                    <Toast.Header className="grad">
                        <svg className="svg-icon me-2">
                            <use xlinkHref={withPrefix(`sprite.svg#copy-icon`)}></use>
                        </svg>
                        <span className="me-auto">Буфер обмена</span>
                    </Toast.Header>
                    <Toast.Body dangerouslySetInnerHTML={{ __html: showBadToast ? '<b>Не удалось</b> скопировать текст' : 'Текст <b>скопирован</b>' }} />
                </Toast>
            </ToastContainer>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;