export const ADJECTIVES = [
    "Abundant", "Adorable", "Adventurous", "Agile", "Alert", "Alive", "Amused", "Ancient", "Angry", "Annoyed",
    "Anxious", "Appetizing", "Arrogant", "Artistic", "Ashamed", "Attractive", "Average", "Awful", "Balanced", "Bashful",
    "Beautiful", "Bewildered", "Bitter", "Bland", "Blissful", "Blue", "Boiling", "Bored", "Brave", "Bright",
    "Brilliant", "Busy", "Calm", "Cautious", "Cheerful", "Chubby", "Clumsy", "Colorful", "Comfortable", "Confused",
    "Content", "Cool", "Cozy", "Crazy", "Creepy", "Crisp", "Cross", "Cuddly", "Curious", "Cute",
    "Damp", "Dangerous", "Dark", "Dazzling", "Defeated", "Defiant", "Delicious", "Delightful", "Depressed", "Determined",
    "Dirty", "Disgusting", "Dizzy", "Doubtful", "Dreamy", "Dry", "Dull", "Dusty", "Eager", "Earnest",
    "Easy", "Ecstatic", "Edgy", "Elated", "Elegant", "Embarrassed", "Enchanting", "Encouraging", "Energetic", "Enthusiastic",
    "Envious", "Excited", "Exhausted", "Expensive", "Exuberant", "Fair", "Faithful", "Fancy", "Fantastic", "Fierce",
    "Filthy", "Fine", "Fluffy", "Fluttering", "Foolish", "Fragile", "Frantic", "Fresh", "Friendly", "Frightened",
    "Funny", "Furious", "Gentle", "Glamorous", "Gleaming", "Gloomy", "Glorious", "Graceful", "Grateful", "Greedy",
    "Grimy", "Grumpy", "Handsome", "Happy", "Harsh", "Healthy", "Heavy", "Helpful", "High", "Hilarious", "Hopeful",
    "Horrible", "Hot", "Huge", "Humble", "Hungry", "Hurried", "Hurt", "Icy", "Ignorant", "Ill",
    "Immense", "Impatient", "Incredible", "Industrious", "Innocent", "Inquisitive", "Itchy", "Jealous", "Jolly", "Joyful",
    "Juicy", "Kind", "Lazy", "Light", "Lively", "Lonely", "Long", "Loose", "Lost", "Loud",
    "Lucky", "Lush", "Magical", "Majestic", "Merry", "Messy", "Mighty", "Miserable", "Misty", "Modern",
    "Moist", "Motionless", "Muddy", "Mysterious", "Narrow", "Nasty", "Naughty", "Nervous", "Nice", "Noisy",
    "Nosy", "Nutty", "Obedient", "Obnoxious", "Odd", "Old", "Orange", "Orderly", "Outrageous", "Outstanding",
    "Overjoyed", "Panicky", "Peaceful", "Perfect", "Perky", "Plain", "Plump", "Polite", "Poor", "Powerful",
    "Precious", "Pretty", "Proud", "Puffy", "Puzzled", "Quaint", "Queasy", "Quick", "Quiet", "Radiant",
    "Rainy", "Rapid", "Rare", "Raspy", "Real", "Relieved", "Repulsive", "Respectful", "Rich", "Ripe",
    "Roasted", "Robust", "Rotten", "Rough", "Round", "Royal", "Rusty", "Sad", "Safe", "Salty",
    "Sane", "Sarcastic", "Satisfied", "Scary", "Scattered", "Scrawny", "Selfish", "Shabby", "Shallow", "Sharp",
    "Shiny", "Short", "Shy", "Silly", "Skinny", "Sloppy", "Slow", "Small", "Smart", "Smiling",
    "Smooth", "Soft", "Soggy", "Solid", "Sour", "Sparkling", "Speedy", "Spicy", "Splendid", "Spongy",
    "Spotless", "Square", "Stale", "Steady", "Steep", "Sticky", "Stiff", "Stormy", "Straight", "Strange",
    "Strong", "Stunning", "Successful", "Sweet", "Swift", "Tall", "Tame", "Tart", "Tasty", "Tender",
    "Tense", "Terrible", "Thankful", "Thick", "Thin", "Thirsty", "Thorny", "Thoughtful", "Thundering", "Tight",
    "Tiny", "Tired", "Tough", "Tricky", "Trite", "Troubled", "Ugly", "Uninterested", "Unique", "Unknown",
    "Unusual", "Upset", "Useful", "Useless", "Vacant", "Vague", "Vast", "Victorious", "Vivid", "Wandering",
    "Warm", "Weak", "Weary", "Weird", "Wet", "Whimsical", "White", "Wide", "Wild", "Witty",
    "Wobbly", "Wonderful", "Worried", "Wrathful", "Wretched", "Yawning", "Yellow", "Young", "Zany", "Zealous",
    "Abandoned", "Abashed", "Aberrant", "Abhorrent", "Abiding", "Abject", "Ablaze", "Able", "Abnormal", "Aboard",
    "Aboriginal", "Abortive", "Abounding", "Above", "Abrasive", "Abrupt", "Absent", "Absorbed", "Absorbing", "Abstracted",
    "Absurd", "Abusive", "Acceptable", "Accessible", "Accidental", "Accurate", "Acidic", "Acidic", "Acoustic",
    "Acrid", "Actionable", "Active", "Actual", "Acute", "Adamant", "Adamant", "Adaptable", "Addicted", "Adhesive",
    "Adjoining", "Adorable", "Adventurous", "Aerial", "Afraid", "Aggressive", "Agile", "Agonizing", "Airtight", "Alert",
    "Alcoholic", "Alert", "Alleged", "Alluring", "Aloof", "Altruistic", "Amazing", "Amusing", "Amused", "Amused",
    "Anarchic", "Ancient", "Angelic", "Angry", "Anguished", "Animated", "Annoyed", "Annoying", "Annual", "Anxious",
    "Anxious", "Apologetic", "Appetizing", "Applicable", "Apprehensive", "Appropriate", "Apt", "Aquatic", "Aromatic", "Arrogant",
    "Artificial", "Artistic", "Ashamed", "Ashen", "Aspiring", "Assorted", "Assured", "Astonishing", "Astounding", "Athletic",
    "Attentive", "Attractive", "Auspicious", "Automatic", "Avaricious", "Average", "Aware", "Awesome", "Awful", "Awkward",
    "Babyish", "Back", "Bad", "Bad", "Baggy", "Baleful", "Banal", "Bandaged", "Bashful", "Basic",
    "Batty", "Batty", "Bawdy", "Beaming", "Beautiful", "Beefy", "Befitting", "Believable", "Bellicose", "Belligerent",
    "Benevolent", "Benign", "Bent", "Berserk", "Best", "Better", "Bewildered", "Big", "Big-hearted", "Biodegradable",
    "Bite-sized", "Bitter", "Bizarre", "Black", "Black-and-white", "Blameworthy", "Bland", "Blank", "Blaring", "Blasphemous",
    "Blatant", "Bleak", "Bleeding", "Blessed", "Blind", "Blissful", "Blond", "Bloody", "Blushing", "Blustery",
    "Boiling", "Bold", "Boorish", "Boring", "Bouncy", "Boundless", "Bountiful", "Brainy", "Brash", "Brave",
    "Brawny", "Breakable", "Breezy", "Brief", "Bright", "Brilliant", "Brimming", "Brisk", "Broken", "Broken-hearted",
    "Bronze", "Brown", "Bruised", "Bubbly", "Bulky", "Bumpy", "Buoyant", "Burly", "Busy", "Buttery",
    "Cagey", "Calm", "Calm", "Candid", "Capable", "Capital", "Capricious", "Captivating", "Carefree", "Careful",
    "Careless", "Caring", "Cautious", "Ceaseless", "Celebrated", "Certain", "Changeable", "Charming", "Cheap", "Cheerful",
    "Cheerless", "Cheery", "Chic", "Chief", "Childlike", "Chilly", "Chivalrous", "Chubby", "Chunky", "Civic",
    "Civil", "Civilized", "Clammy", "Classic", "Classy", "Clean", "Clear", "Clear-cut", "Clever", "Clueless",
    "Clumsy", "Cluttered", "Coarse", "Cold", "Colorful", "Colorless", "Colossal", "Combative", "Comfortable", "Common",
    "Companionable", "Compassionate", "Competent", "Complete", "Complex", "Complicated", "Composed", "Concerned", "Condemned", "Confident",
    "Confidential", "Conforming", "Confused", "Conscientious", "Considerate", "Constant", "Contemplative", "Contemporary", "Content", "Contented",
    "Conventional", "Cool", "Cooperative", "Coordinated", "Corny", "Corpulent", "Correct", "Corrupt", "Costly", "Courageous",
    "Courageous", "Courteous", "Cozy", "Crabby", "Craven", "Crazy", "Creamy", "Creative", "Creepy", "Crisp",
    "Crooked", "Crowded", "Cruel", "Crushing", "Cuddly", "Cultured", "Cumbersome", "Curious", "Curly", "Curved",
    "Curvy", "Cut", "Cute", "Cute", "Cynical", "Daffy", "Daily", "Damaged", "Damaging", "Damp",
    "Dangerous", "Dapper", "Daring", "Dark", "Dazzling", "Dead", "Deadpan", "Deafening", "Dear", "Dearest",
    "Decayed", "Decent", "Decisive", "Deep", "Deeply", "Defeated", "Defective", "Defiant", "Delicate", "Delicious",
    "Delightful", "Delirious", "Demanding", "Dense", "Dependent", "Dependable", "Depressed", "Deranged", "Descriptive", "Deserted",
    "Despicable", "Determined", "Devoted", "Devout", "Dexterous", "Diabolical", "Different", "Difficult", "Digital", "Diligent",
    "Dim", "Diminutive", "Direct", "Dirty", "Disagreeable", "Disastrous", "Discreet", "Discrete", "Disgruntled", "Disgusting",
    "Dishonest", "Disloyal", "Dismal", "Distant", "Distinct", "Distinctive", "Distinguished", "Distorted", "Disturbed", "Dizzy",
    "Dizzying", "Dopey", "Doubtful", "Drab", "Drained", "Dramatic", "Dreary", "Drunk", "Dry", "Dull",
    "Dusty", "Dynamic", "Eager", "Early", "Earnest", "Eatable", "Economic", "Edible", "Educated", "Educational",
    "Eerie", "Efficient", "Effortless", "Elaborate", "Elastic", "Elated", "Elderly", "Electric", "Elegant", "Elementary",
    "Elfin", "Elite", "Emaciated", "Embarrassed", "Embarrassing", "Embattled", "Embellished", "Eminent", "Emotional", "Empty",
    "Enchanting", "Enchanted", "Encouraging", "Endearing", "Endless", "Endurable", "Energetic", "Enormous", "Enthusiastic", "Entire",
    "Envious", "Equal", "Equatorial", "Erect", "Eroded", "Essential", "Esteemed", "Ethical", "Euphoric", "Even",
    "Evergreen", "Every", "Evil", "Exacting", "Excellent", "Excessive", "Excited", "Exciting", "Exclusive", "Exhausted",
    "Exhilarated", "Exotic", "Expensive", "Experimental", "Expert", "Exquisite", "Extensive", "Extra-large", "Extra-small", "Exuberant",
    "Exultant", "Fabulous", "Faded", "Faint", "Fair", "Faithful", "Fake", "False", "Familiar", "Famous",
    "Fanatical", "Fancy", "Fantastic", "Far", "Fare", "Far-flung", "Fascinated", "Fascinating", "Fashionable", "Fast",
    "Fastidious", "Fat", "Faulty", "Fearful", "Fearless", "Feisty", "Feline", "Feminine", "Fertile", "Festive",
    "Fierce", "Filthy", "Fine", "Firm", "Flabby", "Flagrant", "Flaky", "Flamboyant", "Flashy", "Flat",
    "Flatulent", "Flawed", "Fleet", "Fleshy", "Flexible", "Flimsy", "Flippant", "Flirty", "Flowery", "Fluffy",
    "Fluid", "Flustered", "Focused", "Foolhardy", "Foolish", "Forbearing", "Forceful", "Forked", "Formal", "Formidable",
    "Forsaken", "Fortunate", "Fragile", "Frail", "Frank", "Frantic", "Frayed", "Free", "Freezing", "Frequent",
    "Fresh", "Fretful", "Friendly", "Frightened", "Frigid", "Frigid", "Frilly", "Frivolous", "Frizzy", "Frosty",
    "Frozen", "Frugal", "Fruitful", "Fruitless", "Frustrated", "Fulfilling", "Full", "Fumbling", "Functional", "Funny",
    "Furious", "Furry", "Furtive", "Fussy", "Future", "Futuristic", "Fuzzy", "Gaudy", "Gentle", "Genuine",
    "Ghastly", "Giddy", "Gigantic", "Gifted", "Glamorous", "Gleaming", "Gleeful", "Glistening", "Gloomy", "Glorious",
    "Glossy", "Glowing", "Glum", "Golden", "Good", "Gorgeous", "Graceful", "Gracious", "Grand", "Grandiose",
    "Grateful", "Gratis", "Grave", "Gray", "Great", "Greedy", "Green", "Greedy", "Grieving", "Grim",
    "Grimy", "Gripping", "Gritty", "Grotesque", "Grouchy", "Grounded", "Grubby", "Gruesome", "Grumpy", "Guilty",
    "Gullible", "Gusty", "Gutless", "Haggard", "Hairy", "Half", "Half-assed", "Half-baked", "Half-hearted", "Handsome",
    "Handy", "Happy", "Happy-go-lucky", "Hard", "Hard-to-find", "Hard-working", "Harebrained", "Harmful", "Harmless", "Harmonious",
    "Harsh", "Hasty", "Hateful", "Haunted", "Haunting", "Healthy", "Heartbreaking", "Heartbroken", "Heartless", "Heartwarming",
    "Hearty", "Heavenly", "Heavy", "Hefty", "Helpful", "Helpless", "Hesitant", "Hideous", "High", "Highfalutin",
    "High-pitched", "Hilarious", "Hissing", "Historic", "Historical", "Hoarse", "Hollow", "Holy", "Homeless", "Homely",
    "Homemade", "Homey", "Honest", "Honorable", "Hopeful", "Hospitable", "Hot", "Huge", "Humble", "Humdrum",
    "Humorous", "Hungry", "Hurried", "Hurt", "Hurtful", "Hushed", "Husky", "Hypnotic", "Hysterical", "Icy",
    "Ideal", "Idealistic", "Identical", "Idiotic", "Idle", "Idyllic", "Ill", "Ill-fated", "Ill-informed", "Illiterate",
    "Illegal", "Illegible", "Illustrious", "Imaginary", "Imaginative", "Immaculate", "Imminent", "Immense", "Immodest", "Immoral",
    "Immortal", "Immovable", "Impartial", "Impassive", "Impeccable", "Imperfect", "Impolite", "Important", "Impossible", "Impractical",
    "Impressionable", "Impressive", "Improbable", "Improved", "Improper", "Impudent", "Impulsive", "Inaccurate", "Inadequate", "Inadvertent",
    "Inanimate", "Inappropriate", "Inarticulate", "Inattentive", "Inborn", "Incalculable", "Incapable", "Incessant", "Incidental", "Inclusive",
    "Incoherent", "Incompetent", "Incomprehensible", "Inconclusive", "Inconsistent", "Incontrovertible", "Inconvenient", "Incorrect", "Incorrigible", "Incredible",
    "Indecent", "Indecisive", "Indefinite", "Indelible", "Indestructible", "Indifferent", "Indignant", "Indirect", "Indiscreet", "Indispensable",
    "Individual", "Indomitable", "Industrious", "Inedible", "Ineffective", "Inept", "Inert", "Inevitable", "Inexact", "Inexpensive",
    "Inexperienced", "Inexplicable", "Inexpressible", "Inextricable", "Infallible", "Infamous", "Infantile", "Infatuated", "Inferior", "Infinite",
    "Inflated", "Inflexible", "Informal", "Informed", "Ingenious", "Ingrained", "Inherent", "Inhibited", "Inhospitable", "Inhuman",
    "Inhumane", "Initial", "Injured", "Innocent", "Innovative", "Innumerable", "Inoffensive", "Inquisitive", "Insecure", "Insidious",
    "Insightful", "Insignificant", "Insincere", "Insipid", "Insistent", "Insolent", "Insouciant", "Inspired", "Inspiring", "Instant",
    "Instantaneous", "Institutional", "Insufficient", "Insulted", "Insulting", "Integral", "Intelligent", "Intense", "Intensive", "Intent",
    "Intentional", "Interchangeable", "Interesting", "Interior", "Intermittent", "Internal", "International", "Intrepid", "Intrigued", "Intriguing",
    "Intuitive", "Invaluable", "Invariable", "Inventive", "Invincible", "Invisible", "Involved", "Irate", "Irrational", "Irritable",
    "Irritating", "Isolated", "Itchy", "Jaded", "Jagged", "Jam-packed", "Jarring", "Jaunty", "Jealous", "Jittery",
    "Jocular", "Joint", "Jolly", "Jovial", "Joyful", "Joyless", "Joyous", "Judicious", "Juicy", "Jumbled",
    "Jumpy", "Juvenile", "Kaput", "Keen", "Key", "Kind", "Kind-hearted", "Kindly", "Kindred", "Kitschy",
    "Knobby", "Knotty", "Knowing", "Knowledgeable", "Known", "Labored", "Lackadaisical", "Lacking", "Lame", "Lamentable",
    "Landlocked", "Languid", "Languorous", "Large", "Last", "Late", "Laudable", "Laughable", "Lavish", "Lawful",
    "Lazy", "Leafy", "Lean", "Learned", "Leathery", "Leery", "Left", "Legal", "Legitimate", "Leisurely",
    "Lengthy", "Lenient", "Lethal", "Level", "Lewd", "Liar", "Liberated", "Liberal", "Libidinous", "Lickety-split",
    "Lighthearted", "Lightweight", "Likable", "Likeable", "Likely", "Limited", "Limber", "Limp", "Limping", "Linear",
    "Lined", "Lingering", "Liquid", "Literate", "Little", "Live", "Lively", "Livid", "Living", "Loathsome",
    "Local", "Lofty", "Logical", "Lone", "Lonely", "Long", "Long-term", "Loose", "Lopsided", "Lost",
    "Loud", "Loutish", "Lovable", "Lovely", "Loving", "Low", "Lowly", "Loyal", "Lucky", "Lucrative",
    "Ludicrous", "Lumpy", "Lush", "Lustful", "Lustrous", "Luxuriant", "Luxurious", "Lyrical", "Macabre", "Mad",
    "Maddening", "Madly", "Magical", "Magnanimous", "Magnificent", "Majestic", "Major", "Makeshift", "Malicious", "Mammoth",
    "Manageable", "Maniacal", "Manky", "Manly", "Mannerly", "Many", "Masculine", "Massive", "Masterful", "Masterly",
    "Material", "Materialistic", "Maternal", "Mature", "Mean", "Meaningful", "Measly", "Meaty", "Medical", "Medicinal",
    "Medium", "Meek", "Melancholic", "Melancholy", "Mellow", "Melodic", "Melodious", "Melodramatic", "Memorable", "Menacing",
    "Mental", "Merciful", "Merciless", "Mercurial", "Merry", "Messy", "Metaphysical", "Methodical", "Meticulous", "Mild",
    "Military", "Milky", "Mind-bending", "Mindful", "Mindless", "Miniature", "Minimal", "Minor", "Minute", "Mischievous",
    "Miscreant", "Miserable", "Miserly", "Misguided", "Misleading", "Misty", "Mixed", "Mocking", "Modern", "Modest",
    "Moist", "Moldy", "Molecular", "Molten", "Momentous", "Monetary", "Money-grubbing", "Monotonous", "Monstrous", "Moody",
    "Moonlit", "Morbid", "Mortal", "Motionless", "Motivated", "Motley", "Motor", "Mountainous", "Mournful", "Moving",
    "Mucho", "Muddled", "Muddy", "Muffled", "Multicolored", "Multifaceted", "Multipurpose", "Mundane", "Murky", "Mushy",
    "Musical", "Musty", "Muted", "Mysterious", "Mystical", "Naive", "Naked", "Narrow", "Nasal", "Nasty",
    "Nasty", "Natural", "Naughty", "Nautical", "Near", "Neat", "Nebulous", "Necessary", "Needless", "Needy",
    "Negative", "Neglected", "Negligent", "Nervous", "Neurotic", "Neutral", "New", "New-fangled", "Nice", "Nifty",
    "Nimble", "Nippy", "Noble", "Noiseless", "Noisy", "Nonchalant", "Noncommittal", "Nondescript", "Nonexistent", "Nonstop",
    "Nonviolent", "Nonsensical", "Normal", "Notable", "Noted", "Noteworthy", "Novel", "Noxious", "Nuclear", "Numberless",
    "Numerous", "Nutritious", "Nutty", "Oafish", "Obedient", "Obese", "Obnoxious", "Obscene", "Obsequious", "Observant",
    "Obsessive", "Obsolete", "Obtainable", "Obvious", "Occasional", "Oceangoing", "Oceanic", "Odd", "Oddball", "Offbeat",
    "Offensive", "Official", "Oily", "Olive", "Omnipotent", "Omniscient", "One", "One-handed", "One-legged", "One-sided",
    "One-way", "Open", "Open-minded", "Operable", "Operatic", "Operational", "Operative", "Opportune", "Opposite", "Oppressed",
    "Optimal", "Optimistic", "Optimum", "Opulent", "Orange", "Ordinary", "Organic", "Organized", "Oriental", "Original",
    "Ornate", "Other", "Outdoor", "Outgoing", "Outlandish", "Outrageous", "Outstanding", "Overconfident", "Overjoyed", "Overrated",
    "Oversized", "Overwrought", "Overwhelming", "Painful", "Painstaking", "Pale", "Paltry", "Panicky", "Panoramic", "Parallel",
    "Paralyzed", "Paranoid", "Parochial", "Passable", "Passionate", "Passive", "Past", "Pastel", "Paternal", "Patient",
    "Patterned", "Peaceful", "Pear-shaped", "Peculiar", "Pedantic", "Pedestrian", "Peevish", "Peppery", "Perfect", "Perfumed",
    "Perilous", "Perky", "Permanent", "Permissible", "Pernicious", "Perpetual", "Perplexed", "Perplexing", "Persistent", "Personal",
    "Persuasive", "Pertinent", "Pessimistic", "Petite", "Petty", "Petrified", "Petulant", "Phantom", "Phobic", "Phony",
    "Physical", "Picayune", "Piercing", "Pink", "Pious", "Piquant", "Pithy", "Pitiful", "Pitiless", "Pivotal",
    "Placid", "Plaid", "Plain", "Plain", "Plaintive", "Plausible", "Playful", "Pleasant", "Pleased", "Pleasing",
    "Plucky", "Plump", "Plush", "Pointed", "Pointless", "Poised", "Polished", "Polite", "Political", "Pompous",
    "Ponderous", "Pony", "Poor", "Poorly", "Popular", "Porous", "Portable", "Positive", "Possessive", "Possible",
    "Potable", "Potential", "Powerful", "Powerless", "Practical", "Pragmatic", "Preceding", "Precious", "Precise", "Predatory",
    "Predictable", "Preeminent", "Preferred", "Prehistoric", "Prejudiced", "Premature", "Premeditated", "Preposterous", "Prescient", "Present",
    "Presentable", "Prestigious", "Pretty", "Previous", "Pricey", "Prickly", "Prim", "Prime", "Primitive", "Principled",
    "Prior", "Prismatic", "Private", "Prize", "Prized", "Probable", "Problematic", "Productive", "Proficient", "Profitable",
    "Profound", "Profuse", "Proletarian", "Prolific", "Prominent", "Prompt", "Proper", "Prosaic", "Prospective", "Protective",
    "Proud", "Proverbial", "Provincial", "Prudent", "Psychedelic", "Psychotic", "Puffy", "Puny", "Pure", "Purple",
    "Purring", "Pushy", "Putrid", "Puzzled", "Puzzling", "Quack", "Quaint", "Qualified", "Quarrelsome", "Queasy",
    "Querulous", "Questionable", "Quick", "Quick-witted", "Quickest", "Quiet", "Quirky", "Quixotic", "Quizzical", "Rabid",
    "Racial", "Ragged", "Rainy", "Rambunctious", "Rampant", "Rancid", "Rapid", "Rare", "Raspy", "Rational",
    "Ratty", "Raucous", "Raw", "Ready", "Real", "Realistic", "Reasonable", "Rebellious", "Receptive", "Reckless",
    "Recognizable", "Recondite", "Rectangular", "Red", "Redundant", "Refined", "Reflective", "Refreshing", "Refugee", "Regal",
    "Regretful", "Regular", "Relevant", "Reliable", "Relieved", "Reluctant", "Remarkable", "Remorseful", "Remote", "Repentant",
    "Repellent", "Repulsive", "Reputable", "Resilient", "Resolute", "Resonant", "Responsible", "Responsive", "Restless", "Restorative",
    "Retarded", "Retiring", "Revolutionary", "Revulsive", "Rhetorical", "Rich", "Ridiculous", "Right", "Righteous", "Rightful",
    "Rigid", "Ritzy", "Rival", "Roasted", "Robust", "Romantic", "Roomy", "Rosy", "Rotating", "Rotten",
    "Rotund", "Rough", "Round", "Routine", "Rowdy", "Royal", "Rubbery", "Ruddy", "Rude", "Rugged",
    "Ruined", "Ruling", "Rural", "Rusty", "Ruthless", "Sacred", "Sad", "Safe", "Sagacious", "Sage",
    "Salty", "Same", "Sanctimonious", "Sandy", "Sane", "Sarcastic", "Sardonic", "Sassy", "Satin", "Satisfied",
    "Satisfying", "Saucy", "Savage", "Savory", "Scandalous", "Scant", "Scary", "Scattered", "Scenic", "Scented",
    "Scholarly", "Scientific", "Scintillating", "Scornful", "Scrawny", "Scruffy", "Scrumptious", "Scruffy", "Scrupulous", "Scattered",
    "Seamless", "Second", "Second-hand", "Secret", "Secretive", "Secure", "Sedate", "Seemly", "Seething", "Select",
    "Selfish", "Selfless", "Semiprecious", "Sensible", "Sensitive", "Sensual", "Sensuous", "Separate", "Serene", "Serious",
    "Servile", "Severe", "Shabby", "Shadowy", "Shady", "Shaggy", "Shaky", "Shallow", "Shameful", "Shameless",
    "Shapeless", "Sharp", "Shimmering", "Shiny", "Shivering", "Shocking", "Shoddy", "Short", "Short-term", "Showy",
    "Shrewd", "Shrill", "Shy", "Sick", "Silent", "Silky", "Silly", "Simple", "Simplistic", "Sinful",
    "Single", "Sinister", "Sizzling", "Skeletal", "Skeptical", "Skimpy", "Skinny", "Slamming", "Slaphappy", "Slippery",
    "Slipshod", "Sloppy", "Slow", "Sluggish", "Slushy", "Sly", "Small", "Smart", "Smelly", "Smiling",
    "Smoggy", "Smooth", "Snappy", "Snarling", "Sneaky", "Sniveling", "Snoopy", "Snotty", "Snowy", "Snug",
    "Soaring", "Sociable", "Social", "Soft", "Soggy", "Sole", "Solid", "Solitary", "Somber", "Soothing",
    "Sophisticated", "Sordid", "Sore", "Sorrowful", "Sorry", "Soulful", "Soulless", "Sound", "Sour", "Spacey",
    "Spanish", "Spare", "Sparkling", "Sparse", "Specific", "Spectacular", "Spectral", "Spicy", "Spiffy", "Spiky",
    "Spirited", "Spiritual", "Spiteful", "Splendid", "Spontaneous", "Spooky", "Sporty", "Spotless", "Spotted", "Spotty",
    "Sprightly", "Springy", "Square", "Squeaky", "Squealing", "Squiggly", "Stable", "Stained", "Stale", "Stalwart",
    "Standard", "Starchy", "Stark", "Star-spangled", "Star-studded", "Starry", "Starving", "Stately", "Statuesque", "Steadfast",
    "Steady", "Steel", "Steep", "Stereotyped", "Sticky", "Stiff", "Stifling", "Still", "Stingy", "Stinky",
    "Stirring", "Stocky", "Stoic", "Stolen", "Stony", "Stormy", "Stout", "Straight", "Straightforward", "Strange",
    "Strapping", "Strategic", "Streaky", "Streetwise", "Stressful", "Strict", "Striking", "Striped", "Strong", "Strong-willed",
    "Stuck", "Stuffy", "Stunning", "Stupendous", "Stupid", "Sturdy", "Stylish", "Suave", "Subdued", "Subjective",
    "Submissive", "Substantial", "Subtle", "Suburban", "Succinct", "Successful", "Successive", "Sudden", "Sufficient", "Sugary",
    "Suitable", "Sulky", "Sullen", "Sunny", "Super", "Superb", "Superficial", "Superior", "Superlative", "Supernatural",
    "Supersonic", "Superstitious", "Supple", "Supportive", "Supreme", "Sure-footed", "Surly", "Surprised", "Surprising", "Surreal",
    "Suspicious", "Sustainable", "Swank", "Sweaty", "Sweet", "Swift", "Swimming", "Swinging", "Swollen", "Swooping",
    "Sympathetic", "Symptomatic", "Systematic", "Tacky", "Tactful", "Tactical", "Tactless", "Talkative", "Tall", "Tame",
    "Tangible", "Tangy", "Tanned", "Tantalizing", "Tardy", "Tart", "Tasteful", "Tasteless", "Tasty", "Tattered",
    "Taut", "Tawdry", "Teeming", "Teeny", "Teeny-tiny", "Telepathic", "Televised", "Temporary", "Tempting", "Ten",
    "Tender", "Tense", "Terrible", "Terrific"
];

export const NOUNS = [
    "Aardvark", "Albatross", "Alligator", "Alpaca", "Anaconda", "Ant", "Anteater", "Antelope", "Ape", "Aphid",
    "Armadillo", "Baboon", "Badger", "Barracuda", "Basilisk", "Bat", "Bear", "Beaver", "Bee", "Beetle",
    "Bison", "Boar", "Buffalo", "Butterfly", "Camel", "Caracal", "Caribou", "Carp", "Cat", "Caterpillar",
    "Cheetah", "Chimpanzee", "Chipmunk", "Clam", "Cobra", "Cockroach", "Cod", "Condor", "Cormorant", "Cougar",
    "Coyote", "Crab", "Crane", "Crocodile", "Crow", "Cuckoo", "Deer", "Dingo", "Dinosaur", "Dog",
    "Dolphin", "Donkey", "Dove", "Dragon", "Dragonfly", "Duck", "Eagle", "Earthworm", "Eel", "Elephant",
    "Elk", "Emu", "Falcon", "Ferret", "Finch", "Firefly", "Fish", "Flamingo", "Fly", "Fox", "Frog",
    "Gazelle", "Gecko", "Gerbil", "GiantPanda", "Giraffe", "Gnat", "Gnu", "Goat", "Goldfish", "Goose",
    "Gorilla", "Grasshopper", "Grizzly bear", "Grouse", "GuineaPig", "Guppy", "Hamster", "Hare", "Hawk",
    "Hedgehog", "Herring", "Hippopotamus", "Horse", "Hummingbird", "HumpbackWhale", "Hyena", "Iguana", "Impala", "Jackal",
    "Jaguar", "Jellyfish", "Kangaroo", "Koala", "KomodoDragon", "Kookaburra", "Ladybird", "Lamb", "Lamprey", "Leopard",
    "Lion", "Lizard", "Llama", "Lobster", "Locust", "Lynx", "Macaw", "Magpie", "Mallard", "Mammoth",
    "Manatee", "Mandrill", "MantaRay", "Marmoset", "Marten", "Meerkat", "Mink", "Minnow", "Mole", "Mongoose",
    "Monkey", "Moose", "Mosquito", "Mouse", "Mule", "Narwhal", "Newt", "Nightjar", "Octopus", "Okapi",
    "Opossum", "Orangutan", "Orca", "Ostrich", "Otter", "Owl", "Ox", "Oyster", "Panda", "Panther",
    "Parrot", "Peacock", "Pelican", "Penguin", "Pheasant", "Pig", "Pigeon", "Pike", "Piranha", "Platypus",
    "PolarBear", "Porcupine", "Porpoise", "Prawn", "Puffin", "Puma", "Python", "Quail", "Rabbit", "Raccoon",
    "Ram", "Rat", "Raven", "RedPanda", "Reindeer", "Rhinoceros", "Salamander", "Salmon", "SandDollar", "Sandpiper",
    "Scorpion", "Seahorse", "Seal", "Shark", "Sheep", "Shrew", "Shrimp", "Skunk", "Sloth", "Slug",
    "Snail", "Snake", "Sparrow", "Spider", "Spoonbill", "Squid", "Squirrel", "Starfish", "Starling", "Stingray",
    "Stoat", "Stork", "Swan", "Tamarin", "Tapir", "Tarantula", "Tarsier", "Termite", "Tiger", "Toad",
    "Tortoise", "Toucan", "Trout", "Turtle", "Viper", "Vole", "Vulture", "Wallaby", "Walrus", "Wasp",
    "WaterBuffalo", "Weasel", "Whale", "Whippet", "WhiteRhinoceros", "Wildcat", "Wolf", "Wolverine", "Wombat", "Woodpecker",
    "Worm", "Wren", "Yak", "Zebra", "Zebu", "Accordion", "Aeroplane", "Album", "Alphabet", "Amplifier", "Anchor", "Antenna",
    "Apple", "Apricot", "Aquarium", "Arch", "Armchair", "Artichoke", "Ashtray", "Asparagus", "Astronaut", "Atlas", "Avocado",
    "Backpack", "Baguette", "Balloon", "Banana", "Bandana", "Bangle", "Banjo", "Barbecue", "Barometer", "Basket", "Bassoon",
    "Bathtub", "Battery", "Beaker", "Beanie", "Bedspread", "Bell", "Bicycle", "Binder", "Binoculars", "Blender", "Blueberry",
    "Boat", "Bookcase", "Bookmark", "Boomerang", "Bottle", "Bowtie", "Bowl", "Box", "Bracelet", "Briefcase",
    "Broccoli", "Brush", "Bucket", "Buckle", "Buffet", "Bulldozer", "Bumper", "Bun", "Bunkbed", "Burrito",
    "Button", "Cabbage", "Cable", "Cactus", "Calculator", "Calendar", "Camera", "Candle", "Candy", "Canvas",
    "Cap", "Caravan", "Cardigan", "Carrot", "Cashew", "Casserole", "Cauliflower", "Cello", "Cement", "Ceramic",
    "Chair", "Chalk", "Chandelier", "Charger", "Cherry", "Chest", "Chisel", "Chocolate", "Chopsticks", "Cigarette",
    "Cinnamon", "Clamp", "Clarinet", "Cloak", "Clock", "Cloth", "Cloud", "Coaster", "Cocktail", "Coffee",
    "Coffin", "Cologne", "Comb", "Compass", "Computer", "Cone", "Cookbook", "Cookie", "Corkscrew", "Corn",
    "Cotton", "Cowbell", "Crayon", "Cream", "Cup", "Curtain", "Cushion", "Cymbal", "Dartboard", "Deodorant",
    "Dictionary", "Dish", "Doghouse", "Doll", "Door", "Drum", "Duckling", "Duffle", "Earplug", "Easel",
    "Eggplant", "Elastic", "Elevator", "Envelope", "Eraser", "Extension", "Eyeliner", "Facecloth", "Fan", "Faucet",
    "Feather", "Fencing", "Fig", "Filing", "Fireplace", "Fishbowl", "Flag", "Flashlight", "Flask", "Fleece",
    "Flip-flop", "Flute", "Flyswatter", "Foghorn", "Folder", "Footstool", "Fork", "Fossil", "Fountain", "Frying",
    "Fur", "Garlic", "Ginger", "Giraffe", "Glass", "Globe", "Glue", "Go-kart", "Goggles", "Gong",
    "Grapes", "Grater", "Gravy", "Greenhouse", "Grill", "Guitar", "Gum", "Hairbrush", "Hairdryer", "Hammock",
    "Handbag", "Harmonica", "Harp", "Hat", "Headphones", "Heater", "Helmet", "Highlighter", "Hinge", "Honey",
    "Hook", "Hose", "Hotdog", "Hula-hoop", "Humidor", "Icecream", "Incense", "Ink", "Insect", "Instrument",
    "Jackhammer", "Jar", "Jigsaw", "Jug", "Jumper", "Kaleidoscope", "Kazoo", "Kettle", "Key", "Keyboard",
    "Kickstand", "Kite", "Kitten", "Knee-pads", "Knife", "Knitting", "Koala", "Ladle", "Lamp", "Laptop",
    "Lawnmower", "Leek", "Leopard", "Letter", "Lettuce", "Lever", "Lightbulb", "Lighthouse", "Lime", "Lipstick",
    "Lock", "Lollipop", "Luggage", "Lumber", "Lute", "Lycra", "Magnet", "Mallet", "Map", "Maracas",
    "Marker", "Marshmallow", "Mask", "Mat", "Match", "Megaphone", "Microphone", "Microscope", "Mirror", "Mittens",
    "Mixer", "Mobile", "Model", "Mop", "MosquitoNet", "Mouthpiece", "Mug", "Mushroom", "Nail", "Napkin",
    "Needle", "Necklace", "Net", "Notebook", "Notepad", "Novel", "Oar", "Oboe", "Olive", "Onion",
    "Oven", "OxygenTank", "Paddle", "Padlock", "Paintbrush", "Palette", "PalmTree", "Pancake", "Papaya", "Paperclip",
    "Parachute", "Parsley", "Paste", "Pastry", "Peach", "Peanut", "Pear", "Peg", "Pencil", "Pepper",
    "Periscope", "Piano", "Pickaxe", "Pickle", "Pie", "Pillow", "Pineapple", "Ping-pong", "Pinwheel", "Pipe",
    "Piranha", "Pitchfork", "Pizza", "Plank", "Plant", "Plaster", "Platter", "Pliers", "Plum", "Pocketknife",
    "PogoStick", "Pomegranate", "Poncho", "PoolCue", "Popcorn", "Popsicle", "Pot", "Potato", "Pouch", "Pruner",
    "Puddle", "Pulley", "Pump", "Pumpkin", "Puppet", "Purse", "Putty", "Quilt", "Quiver", "Racket",
    "Radar", "Radio", "Raspberry", "Rattle", "Refrigerator", "Remote", "Ribbon", "Ring", "Robot", "Rock",
    "Roller", "Roof", "Rope", "Rubber", "Saddle", "Saffron", "Sail", "Salad", "Sandal", "Sandpaper",
    "Sandwich", "Saw", "Saxophone", "Scarf", "Scoop", "Scrabble", "Screwdriver", "Scrub brush", "Sculpture", "Seashell",
    "Seatbelt", "Seesaw", "Sewing", "Shampoo", "Shawl", "Shears", "Shingle", "Ship", "Shirt", "Shoehorn",
    "Shoe", "Shopping", "Shovel", "Shower", "Shrub", "Shutter", "Sidecar", "Sink", "Skate", "Skateboard",
    "Ski", "Skirt", "Skull", "Sky", "Sled", "Slide", "Sling", "Slipper", "Slope", "Snorkel",
    "Snowflake", "Snowglobe", "Snowman", "Soap", "Sock", "Sofa", "Soybean", "Spade", "Spatula", "Speaker",
    "Spectacles", "Spectrometer", "Speedboat", "Spiderweb", "Spoon", "Sprinkler", "Sponge", "Sprout", "Square", "Stairs",
    "Stamp", "Star", "Statue", "Steak", "Steering", "Stethoscope", "Stool", "Stopwatch", "Straw", "Stretcher",
    "String", "Stroller", "Submarine", "Subwoofer", "Suitcase", "Sundial", "Sunflower", "Sunshade", "Surfboard", "Sushi",
    "Swing", "Switch", "Sword", "Table", "Tablecloth", "Tambourine", "Tank", "Tap", "Tape", "Tapestry",
    "Tarp", "Teabag", "Teacup", "Teapot", "Telescope", "Tent", "Thermometer", "Thread", "Throne", "Ticket",
    "Tie", "Tissue", "Toaster", "Tongs", "Tool", "Toothbrush", "Toothpaste", "Towel", "Toy", "Tractor",
    "TrafficLight", "Trailer", "Train", "Trampoline", "Trapdoor", "Tray", "Treadmill", "Triangle", "Tricycle", "Tripod",
    "Trombone", "Truck", "Truffle", "Tuba", "Tub", "Tugboat", "Tulip", "Tunnel", "Tureen", "Turnip",
    "Typewriter", "Umbrella", "Unicycle", "Uniform", "Utensil", "Vacuum", "Vase", "Vegetable", "Velcro", "Vest",
    "Vine", "Violin", "Visor", "Volcano", "Waffle", "Wall", "Wallet", "Walnut", "Wardrobe", "Warhammer",
    "Washer", "Wastebasket", "Watch", "Watermelon", "Wheel", "Wheelbarrow", "Whistle", "Window", "Windscreen", "Wine",
    "Wing", "Wok", "Wood", "Wrench", "Yacht", "Yarn", "Yo-yo", "Zucchini"
];